import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { User, UserType } from '../store/user.state';
import { UserBook, UserBooks } from '../models/user-book.model';
import { ProfileState } from '../store/profile.state';
import { UrlBuilder } from '../utils/url-builder';

enum ApiEndpoint {
  DoesUserEmailExist = '/auth/email/exists',
  CreateUser = '/auth/email/signup',
  LoginEmail = '/auth/email/login',

  ForgotPassword = '/auth/email/reset-password',
  ChangePassword = '/email-tokens/:uuid/reset-password',
  ResendEmailConfirmation = '/email-tokens/validation-email/resend',
  DeleteUser = '/users/:id',

  UserBook = '/books/:uuid',
  UserFinishedBooks = '/books',
  UserActivityComplete = '/activities/:activityId/completed',
  UserCompletedActivities = '/activities',
}

export interface UserCompletedActivities {
  bookId: string;
  activityId: number;
  completedAt: string;
}

@Injectable({ providedIn: 'root' })
export class UserProvider {
  constructor(
    private http: HttpClient,
    private profileState: ProfileState,
  ) {}

  doesUserExist(email: string): Observable<boolean> {
    return this.http
      .post<{ exists: boolean }>(environment.usersBaseUrl + ApiEndpoint.DoesUserEmailExist, { email })
      .pipe(map(response => response.exists));
  }

  resetPassword(email: string): Observable<void> {
    return this.http.post<void>(environment.usersBaseUrl + ApiEndpoint.ForgotPassword, { email });
  }

  resendConfirmationEmail(): Observable<void> {
    return this.http.post<void>(environment.usersBaseUrl + ApiEndpoint.ResendEmailConfirmation, {});
  }

  changePassword(password: string, uuid: string, jwt: string): Observable<void> {
    const url = new UrlBuilder(ApiEndpoint.ChangePassword).setUrlParams({ uuid }).getUrl();

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + jwt,
      }),
    };

    return this.http.patch<void>(environment.usersBaseUrl + url, { password }, httpOptions);
  }

  createAccount(params: {
    email: string;
    password: string;
    acceptedTerms: boolean;
    acceptedCoppa: boolean;
    country: string;
    language: string;
    projectCode: string;
  }): Observable<User> {
    const url = new UrlBuilder(ApiEndpoint.CreateUser)
      .setQueryParams({
        country: params.country,
      })
      .getUrl();

    return this.http
      .post<{ userId: string; jwt: string; isEmailValidated: boolean }>(environment.usersBaseUrl + url, {
        email: params.email,
        password: params.password,
        acceptedCoppa: params.acceptedCoppa,
        acceptedTerms: params.acceptedTerms,
        language: params.language,
        projectCode: params.projectCode,
      })
      .pipe(
        map(user => ({ id: user.userId, jwt: user.jwt, userType: UserType.USER, isEmailValidated: user.isEmailValidated })),
        catchError(error => {
          return throwError(() => error.error);
        }),
      );
  }

  loginWithEmail(email: string, password: string): Observable<User> {
    return this.http
      .post<{
        isEmailValidated: boolean;
        jwt: string;
        userId: string;
        isLegacy?: boolean;
      }>(environment.usersBaseUrl + ApiEndpoint.LoginEmail, { email, password })
      .pipe(
        map(user => ({
          isLegacy: user.isLegacy || false,
          id: user.userId,
          jwt: user.jwt,
          userType: UserType.USER,
          isEmailValidated: user.isEmailValidated,
        })),
        catchError(error => {
          return throwError(() => error.error);
        }),
      );
  }

  saveUserBook(uuid: string, favourite: boolean, profileId: string): Observable<void> {
    const url = new UrlBuilder(ApiEndpoint.UserBook).setUrlParams({ uuid }).setQueryParams({ profileId }).getUrl();

    return this.http.patch<void>(environment.usersBaseUrl + url, { favorite: favourite });
  }

  saveUserBookProgress(params: {
    uuid: string;
    readingTimeSeconds: number;
    chapterItemId: string;
    page: number;
    width: number;
    height: number;
    profileId: string;
  }): Observable<void> {
    const url = new UrlBuilder(ApiEndpoint.UserBook).setUrlParams({ uuid: params.uuid }).setQueryParams({ profileId: params.profileId }).getUrl();

    return this.http.patch<void>(environment.usersBaseUrl + url, {
      readingTimeSeconds: params.readingTimeSeconds,
      itemId: params.chapterItemId,
      page: params.page,
      width: params.width,
      height: params.height,
    });
  }

  getUserBookInfo(uuid: string): Observable<UserBook> {
    const url = new UrlBuilder(ApiEndpoint.UserBook)
      .setUrlParams({ uuid })
      .setQueryParams({ profileId: this.profileState.currentProfile$.value?.id })
      .getUrl();

    return this.http.get<UserBook>(environment.usersBaseUrl + url);
  }

  getUserFinishedBooks(): Observable<UserBooks> {
    const url = new UrlBuilder(ApiEndpoint.UserFinishedBooks)
      .setQueryParams({
        profileId: this.profileState.currentProfile$.value?.id,
        status: 'finished',
      })
      .getUrl();

    return this.http.get<UserBooks>(environment.usersBaseUrl + url);
  }

  getUserCompletedActivities(profileId: string, bookId: string): Observable<UserCompletedActivities[]> {
    const url = new UrlBuilder(ApiEndpoint.UserCompletedActivities).setQueryParams({ profileId, bookId, offset: 0, limit: 9999 }).getUrl();

    return this.http.get<{ activities: UserCompletedActivities[] }>(environment.usersBaseUrl + url).pipe(map(response => response.activities));
  }

  activityComplete(activityId: number, bookUuid: string, profileId: string): Observable<void> {
    const url = new UrlBuilder(ApiEndpoint.UserActivityComplete).setUrlParams({ activityId }).setQueryParams({ profileId }).getUrl();

    return this.http.post<void>(environment.usersBaseUrl + url, { bookId: bookUuid });
  }

  deleteUserAccount(id: string): Observable<void> {
    const url = new UrlBuilder(ApiEndpoint.DeleteUser).setUrlParams({ id }).getUrl();

    return this.http.delete<void>(environment.usersBaseUrl + url);
  }
}
